<template>
    <span>{{timeToString(time)}}</span>
</template>

<script>
import humanizeDuration from 'humanize-duration';

export default {
    props:{
        time:{
            type: Number,
            default: 0
        },

        units:{
            type: Array,
            default: () => ["d", "h", "m"]
        },

        separator:{
            type: String,
            default: null
        },

        past: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        timeToString(time) {
            if(this.units.length == 1 && this.units[0] == "d" && time < 86400000){
                let today = new Date();
                today.setHours(0, 0, 0 ,0);

                let d = new Date(today.getTime() + time);

                if(d.getDate() == today.getDate()) return this.$t("time_units.today");
            }

            if(time <= 0) return this.past? this.$t("time_units.in_future") : this.$t("time_units.now");

            let str = humanizeDuration(time, { delimiter: (this.separator ? this.separator : " " + this.$t("time_units.and") + " "), units: this.units, language: this.$i18n.locale, round: true });

            if(this.$i18n.locale == "pl"){
                str = str.replace(/1 minuta/g, "1 " + this.$t("time_units.mins.single"));
                str = str.replace(/1 godzina/g, "1 " + this.$t("time_units.hours.single"));
            }
            
            return this.past? str + " " + this.$t("time_units.ago") : this.$t("time_units.in") + " " + str;
        },
    }
};
</script>
